<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="categoryTitle"></span>
    </div>
    <!-- editData：{{editData}}
    <hr>
    formData：{{formData}}-->
    <el-form ref="formData" :model="formData" :rules="rules" label-width="120px">
      <el-form-item label="类别名：" prop="title">
        <el-input v-model="formData.title" placeholder="请输入课程类别名称（不可重名）" maxlength="20"></el-input>
      </el-form-item>
      <el-form-item label="上级类别：">
        <!-- 级联选择器 -->
        <el-cascader
          style="width: 100%"
          ref="refHandle"
          v-model="formData.selected"
          :options="categoryList"
          @change="handleChange"
          :props="{ checkStrictly: true, value: 'id', label: 'title' }"
        ></el-cascader>
      </el-form-item>
    </el-form>
    <!-- footer -->
    <el-row slot="footer">
      <el-button type="primary" @click="saveCategory()" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
/**
 * 弹出框-使用封装
 * @attribute show 弹出框状态
 * @attribute editData 根据有无 editData数据 动态渲染 dialog弹出框 数据 和 弹框标题
 * @attribute cascaderList  el-cascader 级联选择器数据
 * @function close 自定义弹窗关闭事件
 */
import { addCatalog, editCatalog } from '@/api/research/curriculum'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    editData: {
      default: () => {
        return {}
      },
      type: Object
    },
    cascaderList: {
      default: () => {
        return []
      },
      type: Array
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return {
      categoryTitle: '',
      formData: {},
      categoryList: [],
      rules: {
        title: [
          { required: true, message: '课程类别名称不能为空', trigger: 'blur' },
          { max: 20, message: '字数限制在20个字内', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    openDialog () {
      this.categoryList = [...this.cascaderList]
      this.formData = {}
      if (this.editData !== null) {
        this.categoryTitle = '编辑课程类别'
        this.formData = { ...this.editData }
        // this.formData = this.editData;

        if (this.editData.pid === '') {
          this.formData.selected = [this.editData.id]
        } else {
          this.formData.selected = [this.editData.pid, this.editData.id]
        }

        this.getTreeArray(this.categoryList, this.formData.selected)
      } else {
        this.categoryTitle = '新增课程类别'
      }
    },

    // 关闭弹窗
    close () {
      this.$emit('close')
    },

    // 遍历树形结构
    getTreeArray (data, val) {
      for (let i in data) {
        if (data[i].id === val[0]) {
          if (data[i].pid !== '') {
            this.formData.selected.unshift(data[i].pid)
            this.getTreeArray(this.categoryList, this.formData.selected)
          }
        } else {
          if (data[i].hasOwnProperty('children')) {
            this.getTreeArray(data[i].children, val)
          }
        }
      }
    },

    // 选择
    handleChange (val) {
      this.formData.selected = val
    },

    // 保存
    saveCategory () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          if (this.editData !== null) {
            editCatalog({
              id: this.formData.id,
              title: this.editData.title === this.formData.title ? null : this.formData.title,
              pid:
                this.editData.id ===
                this.formData.selected[this.formData.selected.length - 1]
                  ? null
                  : this.formData.selected[this.formData.selected.length - 1]
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('编辑成功')
                this.close()
                this.$emit('updateCourseCatalog')
              }
            })
          } else {
            addCatalog({
              title: this.formData.title,
              pid: !this.formData.selected ? '' : this.formData.selected[this.formData.selected.length - 1]
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('新增成功')
                this.close()
                this.$emit('updateCourseCatalog')
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
