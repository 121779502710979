<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    @open="openDialog"
    width="70%"
    top="7vh"
    >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>类别成员管理</span>
    </div>
    <!-- catalogId: {{catalogId}} -->
    <el-row :gutter="50">
      <el-col :span="12">
        <el-row>员工列表</el-row>
        <el-row>
          <el-input
            placeholder="输入搜索用户名、姓名关键字"
            v-model="inputVal"
            style="width: 380px;margin:10px 10px 10px 0"
            @keyup.enter.native="search"
          >
            <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 120px;">
              <el-option label="用户名" value="1"></el-option>
              <el-option label="姓名" value="2"></el-option>
            </el-select>
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <el-button @click="search" type="primary" class="tb-button" size="small">搜索</el-button>
          <el-button @click="reset" type="primary" class="tb-button" size="small">重置</el-button>
        </el-row>
        <el-table
          :data="staffList"
          @selection-change="handleStaffChange"
          border
          fit
          highlight-current-row
        >
          <el-table-column type="selection" align="center"></el-table-column>
          <el-table-column label="用户名" align="center">
            <template slot-scope="scope">{{ scope.row.loginName }}</template>
          </el-table-column>
          <el-table-column label="姓名" align="center">
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button size="mini" plain type="primary" @click="add(scope.row, scope.$index)">添 加</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button @click="batchAdd" type="info" size="small" style="margin-top:20px;">批量添加</el-button>
        <el-pagination
          @current-change="handleStaffCurrentChange"
          :current-page="staffPagination.currentPage"
          :page-size="staffPagination.pageSize"
          layout="prev, pager, next, jumper"
          :total="staffPagination.total">
        </el-pagination>
      </el-col>
      <!-- 已选成员 -->
      <el-col :span="12">
        <el-row>已有成员</el-row>
        <el-row>
          <el-input
            placeholder="输入搜索用户名、姓名关键字"
            v-model="inputValTwo"
            style="width: 380px;margin:10px 10px 10px 0"
            @keyup.enter.native="searchTwo"
          >
            <el-select v-model="selectTwo" slot="prepend" placeholder="请选择" style="width: 120px;">
              <el-option label="用户名" value="1"></el-option>
              <el-option label="姓名" value="2"></el-option>
            </el-select>
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <el-button @click="searchTwo" type="primary" class="tb-button" size="small">搜索</el-button>
          <el-button @click="resetTwo" type="primary" class="tb-button" size="small">重置</el-button>
        </el-row>
        <el-table
          :data="tableData"
          @selection-change="handleSelectionChange"
          border
          fit
          highlight-current-row
        >
          <el-table-column type="selection" align="center"></el-table-column>
          <el-table-column label="用户名" align="center">
            <template slot-scope="scope">{{ scope.row.loginName }}</template>
          </el-table-column>
          <el-table-column label="姓名" align="center">
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button size="mini" plain type="danger" @click="del(scope.row, scope.$index)">删 除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button @click="batchDel" type="info" size="small" style="margin-top:20px;">批量删除</el-button>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-size="pagination.pageSize"
          layout="prev, pager, next, jumper"
          :total="pagination.total">
        </el-pagination>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import { getListEmployee } from '@/api/system/staff'
import { getUserByCatalog, addUserByCatalog, deleteCatalogUser } from '@/api/research/curriculum'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    catalogId: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      staffList: [],
      tableData: [],
      seleData: [],
      seleStaffData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      staffPagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      select: '2',
      inputVal: '',
      inputValTwo: '',
      selectTwo: '2'
    }
  },
  methods: {
    openDialog () {
      this.sele = ''
      this.inputVal = ''
      // 获取员工列表
      this.listEmployee()
      // 获取当前角色列表
      this.getUserByCatalog()
    },
    async listEmployee () {
      const res = await getListEmployee({
        pageNum: this.staffPagination.currentPage,
        pageSize: this.staffPagination.pageSize,
        loginName: this.select === '1' ? this.inputVal : '',
        name: this.select === '2' ? this.inputVal : '',
        state: 1
      })
      this.staffList = res.body.list
      this.staffPagination.total = res.body.total
    },

    // 关闭
    close () {
      this.$emit('close')
    },

    // 根据角色获取用户列表
    async getUserByCatalog () {
      const res = await getUserByCatalog({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
        catalogId: this.catalogId,
        loginName: this.selectTwo === '1' ? this.inputValTwo : '',
        name: this.selectTwo === '2' ? this.inputValTwo : ''
      })
      this.tableData = res.body.list
      this.pagination.total = res.body.total
    },

    // 添加角色用户
    add (row) {
      addUserByCatalog({
        catalogId: this.catalogId,
        userIds: [row.userId]
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('添加成功')
          this.getUserByCatalog()
        } else {
          if (res.errMsg === '用户已存在角色') {
            window.$msg('用户已添加', 2)
          } else {
            window.$msg(res.errMsg, 2)
          }
        }
      })
    },

    // 搜索、重置
    search () {
      this.staffPagination.currentPage = 1
      this.listEmployee()
    },
    reset () {
      this.select = '2'
      this.inputVal = ''
    },

    searchTwo () {
      this.pagination.currentPage = 1
      this.getUserByCatalog()
    },

    resetTwo () {
      this.selectTwo = '2'
      this.inputValTwo = ''
    },

    // 批量添加角色用户
    batchAdd () {
      if (this.seleStaffData.length !== 0) {
        let addArray = []
        this.seleStaffData.forEach((item) => {
          addArray.push(item.userId)
        })
        addUserByCatalog({
          catalogId: this.catalogId,
          userIds: addArray
        }).then(res => {
          if (res.state === 'success') {
            window.$msg('添加成功')
            this.getUserByCatalog()
          } else {
            if (res.errMsg === '用户已存在角色') {
              window.$msg('用户已添加', 2)
            } else {
              window.$msg(res.errMsg, 2)
            }
          }
        })
      } else {
        window.$msg('无勾选数据', 2)
      }
    },

    // 分页
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getUserByCatalog()
    },
    handleStaffCurrentChange (val) {
      this.staffPagination.currentPage = val
      this.listEmployee()
    },
    handleSelectionChange (sele) {
      this.seleData = sele
    },
    handleStaffChange (sele) {
      this.seleStaffData = sele
    },
    del (row, index) {
      this.$confirm('是否删除当前成员？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteCatalogUser({
            catalogId: this.catalogId,
            userIds: [row.id]
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('删除成功')
              this.getUserByCatalog()
            }
          })
        })
        .catch(() => {
          return false
        })
    },
    // 批量删除
    batchDel () {
      if (this.seleData.length !== 0) {
        this.$confirm('是否批量删除当前成员？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            let delArr = []
            this.seleData.forEach((item) => {
              delArr.push(item.id)
            })
            deleteCatalogUser({
              catalogId: this.catalogId,
              userIds: delArr
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('删除成功')
                this.getUserByCatalog()
              }
            })
          })
          .catch(() => {
            return false
          })
      } else {
        window.$msg('无勾选数据', 2)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../style/dialog.scss';
/deep/ .el-table td {
  padding: 10px 0 !important;
}
</style>
