<template>
  <el-row class="table">
    <span class="title">课程类别管理</span>
    <el-row class="row-bg">
      <el-button type="info" size="medium" @click="addCategory()" class="tb-button" v-has="'curriculum:CategoryAdd'">新 增</el-button>
    </el-row>
    <el-table
      :data="tableArr"
      style="width: 100%;margin-bottom: 20px;"
      row-key="id"
      border
      default-expand-all
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <el-table-column prop="title" label="类别名" align="left"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="memberCategory(scope.row)">成员管理</el-button>
          <el-button type="text" @click="editCategory(scope.row)" v-has="'curriculum:CategoryEdit'">编 辑</el-button>
          <el-button type="text" @click="delCategory(scope.row)" v-has="'curriculum:CategoryDel'">删 除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 新增课程类别 -->
    <add-category
      :show="categoryDialog"
      @close="categoryDialog=false;editData=null"
      :editData="editData"
      :cascaderList="cascaderList"
      @updateCourseCatalog="updateCourseCatalog"
    ></add-category>

    <!-- 类别成员管理 -->
    <member-category
      :show="memberDialog"
      :catalogId="catalogId"
      @close="memberDialog=false"
    ></member-category>
  </el-row>
</template>

<script>
import addCategory from '@/components/research/addCategory'
import MemberCategory from '@/components/research/member-category'
import getCourseType from '@/mixins/getCourseType'
import { delCatalog } from '@/api/research/curriculum'

export default {
  components: {
    addCategory,
    MemberCategory
  },
  mixins: [getCourseType],
  data () {
    return {
      categoryDialog: false,
      editData: null,
      memberDialog: false,
      catalogId: ''
    }
  },
  methods: {
    // 添加课程类别
    addCategory () {
      this.categoryDialog = true
    },

    // 编辑
    editCategory (row) {
      this.editData = row
      this.categoryDialog = true
    },

    // 删除
    delCategory (row) {
      this.$confirm('确定删除当前类别吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await delCatalog({
            ids: [row.id]
          })
          window.$msg('删除成功')
          this.courseCatalog()
        })
        .catch(() => {
          return false
        })
    },

    // 操作完成,更新表格数据
    updateCourseCatalog () {
      this.courseCatalog()
    },

    // 成员管理
    memberCategory (row) {
      this.catalogId = row.id
      this.memberDialog = true
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../style/table.scss";
</style>
